import { TAdvancedFilterForm } from '@/components/common/tableComponent/advancedFilters';
import { TTableFilters } from '@/types/table/tableTypes';
import { FieldValues } from 'react-hook-form';

export const mergeFilterString = (
  newFilter: string,
  currentFilter?: string,
) => {
  let $filter = currentFilter || '';
  if (newFilter && !$filter?.includes(newFilter)) {
    $filter = $filter.length > 0 ? `${$filter} and ${newFilter}` : newFilter;
  }
  return $filter;
};

export const splitFilterString = (filter: string) => {
  const filterArr = filter.split(' and ');

  const splittedFiltersArr = filterArr
    .map((filterItem) => {
      if (!filterItem) return;
      const splittedFilterItem = filterItem.split(' ');
      return {
        [splittedFilterItem[0]]: {
          [splittedFilterItem[1]]: {
            value: splittedFilterItem.slice(2).join(' '),
          },
        },
      };
    })
    .filter((item) => item);
  return splittedFiltersArr;
};

type TGetFilterStringCallback = (
  accessorKey: string,
  type: string,
  filterValue: string,
  additionalFilter: string,
) => void;

const getFilterType = (
  filterKey: FieldValues,
  accessorkey: string,
  callBack: TGetFilterStringCallback,
) => {
  for (const type in filterKey) {
    if (Object.prototype.hasOwnProperty.call(filterKey, type)) {
      const filterValue = filterKey[type];
      if (filterValue.value)
        callBack(
          accessorkey,
          type,
          filterValue.value,
          filterValue.aditionalFilter,
        );
    }
  }
};

export const getFilterString = (
  filterKey: FieldValues,
  callBack: TGetFilterStringCallback,
) => {
  for (const accessorkey in filterKey) {
    if (Object.prototype.hasOwnProperty.call(filterKey, accessorkey)) {
      const accessor = filterKey[accessorkey];
      getFilterType(accessor, accessorkey, callBack);
    }
  }
};

export const getDefaultValuesFromQueryParams = (params: FieldValues) => {
  let defaultValues = {};
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const element = params[key];
      defaultValues = {
        ...defaultValues,
        [key.split('q_')[1]]: splitFilterString(element)[0],
      };
    }
  }
  return defaultValues;
};

export const getDefaultFilterString = (defaultFilters: FieldValues) => {
  let filterString = '';
  for (const key in defaultFilters) {
    if (Object.prototype.hasOwnProperty.call(defaultFilters, key)) {
      const filterKey = defaultFilters[key];
      getFilterString(filterKey, (accessorkey, type, filterValue) => {
        filterString = mergeFilterString(
          `${accessorkey} ${type} ${filterValue}`,
          filterString,
        );
      });
    }
  }
  return filterString;
};

export const getFilterCount = (filter: string) => {
  let count = 0;
  const splittedFilter = splitFilterString(filter);
  for (const key in splittedFilter) {
    if (Object.prototype.hasOwnProperty.call(splittedFilter, key)) {
      const filterKey = splittedFilter[key];
      if (!filterKey) continue;
      getFilterString(filterKey, () => {
        count += 1;
      });
    }
  }
  return count;
};

export const generateYearOptions = (
  maxYearsBefore: number,
): { label: string; value: string }[] => {
  const currentYear = new Date().getFullYear();
  const options: { label: string; value: string }[] = [];

  for (let i = 0; i <= maxYearsBefore - 1; i++) {
    options.push({ label: `${currentYear - i}`, value: `${currentYear - i}` });
  }

  return options;
};

export const replaceTemplates = (filter: TTableFilters) => {
  return filter.aditionalFilterTemplateString?.replace(
    '[accessorKey]',
    filter.accessorKey,
  );
};

export const mapAdvancedFilters = (data: TAdvancedFilterForm) => {
  const result: FieldValues = {};

  data.filters.forEach((filter, index) => {
    const { accessorKey, type, value } = filter;
    const key = `advancedFilter${index}`;
    result[key] = {
      [accessorKey || '']: {
        [type || '']: {
          value,
        },
      },
    };
  });

  return result;
};

export const reverseMapAdvancedFilters = (data: FieldValues) => {
  const filters: FieldValues[] = [];

  Object.keys(data).forEach((key) => {
    const accessorObject = data[key];

    Object.keys(accessorObject).forEach((accessorKey) => {
      const typeObject = accessorObject[accessorKey];

      Object.keys(typeObject).forEach((type) => {
        const { value } = typeObject[type];

        filters.push({
          accessorKey,
          type,
          value,
        });
      });
    });
  });

  return { filters };
};
