import { FieldValues, useForm } from 'react-hook-form';
import {
  TFilerInputs,
  TTableFilters,
  isTBooleanFilter,
  isTSelectFilter,
} from '../../../types/table/tableTypes.ts';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faFilterCircleXmark,
  faGears,
} from '@fortawesome/pro-solid-svg-icons';
import { getQueryParamsObj } from '../../../helpers/queryParams.ts';
import { useApplyFilters } from '../../../hooks/useApplyFilters.ts';
import { TranslationContext } from '../../../context/translationContext.ts';
import { Checkbox } from '@signpost-hardware/react-components';
import {
  replaceTemplates,
  reverseMapAdvancedFilters,
} from '@/helpers/filters.ts';
import AdvancedFilters from '@/components/common/tableComponent/advancedFilters.tsx';
import { Column } from '@tanstack/react-table';

type TFilterProps<T> = {
  filters: TTableFilters[];
  columns: Column<T, unknown>[];
  setFilter: Dispatch<SetStateAction<string>>;
  defaultFilters?: FieldValues;
};

const Filters = <T,>({
  filters,
  setFilter,
  defaultFilters,
  columns,
}: TFilterProps<T>) => {
  const translation = useContext(TranslationContext);
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params: FieldValues = getQueryParamsObj(searchParams.entries());
  const { handleSubmit, watch, register, reset, setValue } =
    useForm<FieldValues>({
      defaultValues: defaultFilters || {},
    });
  const applyFilters = useApplyFilters(setFilter);
  let clearObj = {};

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((data) => {
        applyFilters(data);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, applyFilters, params]);

  const filterComponents = filters.map((filter) => {
    const registerKey = `${filter.key}.${filter.accessorKey}.${filter.filterType}.value`;
    const registerAdditionalFilterKey = `${filter.key}.${filter.accessorKey}.${filter.filterType}.aditionalFilter`;
    const additionalFilterString = replaceTemplates(filter);
    clearObj = {
      ...clearObj,
      [filter.key]: {
        [filter.accessorKey]: {
          [filter.filterType]: {
            value: filter.defaultValue || '',
            aditionalFilter: additionalFilterString,
          },
        },
      },
    };

    const registerOptions = {
      onChange: () =>
        setValue(registerAdditionalFilterKey, additionalFilterString),
    };

    const filterFieldsObj: Record<TFilerInputs, () => ReactNode> = {
      select: () => {
        if (!isTSelectFilter(filter))
          throw new Error('Invalid select filter types');
        return (
          <label className='flex flex-col' key={filter.key}>
            {filter.label && <span>{filter.label}</span>}
            <div className='relative'>
              <select
                defaultValue={filter.defaultValue}
                className='w-max appearance-none cursor-pointer bg-app-200 pl-4 pr-10 py-2 rounded-md border border-app-400 flex items-center gap-4 hover:bg-app-400 hover:border-app-500 transition-colors'
                {...register(registerKey, registerOptions)}>
                {filter.options.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <FontAwesomeIcon
                icon={faCaretDown}
                className='absolute top-1/2 right-4 -translate-y-1/2'
              />
            </div>
          </label>
        );
      },
      checkbox: () => {
        if (!isTBooleanFilter(filter))
          throw new Error('Invalid boolean filter types');
        return (
          <Checkbox
            label={filter.label || ''}
            key={filter.key}
            value={filter.value}
            {...register(registerKey, registerOptions)}
          />
        );
      },
    };
    return filterFieldsObj[filter.type]();
  });

  return (
    <>
      <form className='border-t border-app-400 p-4 flex flex-col gap-2'>
        <div className='flex justify-between gap-4'>
          <h2 className='text-xl font-medium'>{translation.filters}</h2>
          <div className='flex flex-col gap-2 items-end'>
            <button
              type='button'
              className='flex items-center gap-2'
              onClick={() => {
                setSearchParams({});
                reset(clearObj);
              }}>
              <FontAwesomeIcon icon={faFilterCircleXmark} />
              <span>{translation.reset}</span>
            </button>
            {columns.length > 0 && (
              <button
                type='button'
                className='flex items-center gap-2'
                onClick={() => {
                  setShowAdvanced((prevState) => !prevState);
                }}>
                <FontAwesomeIcon icon={faGears} />
                <span>{translation.advancedFilters}</span>
              </button>
            )}
          </div>
        </div>
        {!showAdvanced && (
          <div className='flex flex-wrap gap-2'>{filterComponents}</div>
        )}
      </form>
      {showAdvanced && (
        <AdvancedFilters
          columns={columns}
          setFilter={setFilter}
          defaultFilters={reverseMapAdvancedFilters(defaultFilters || {})}
        />
      )}
    </>
  );
};

export default Filters;
