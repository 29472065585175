import { FieldValues } from 'react-hook-form';
import { getFilterString, mergeFilterString } from '../helpers/filters';
import { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useApplyFilters = (
  setFilter: Dispatch<SetStateAction<string>>,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (data: FieldValues) => {
    //! Clear filters before adding all filters again
    setFilter('');
    let newSearchParams = { ...searchParams };
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const filterKey = data[key];
        //! Delete filter in queryParams before adding all filters again
        searchParams.delete(`q_${key}`);
        getFilterString(
          filterKey,
          (accessorkey, type, filterValue, additionalFilter) => {
            setFilter((prevValue) => {
              const merged = mergeFilterString(additionalFilter, prevValue);
              return mergeFilterString(
                merged,
                `${accessorkey} ${type} ${filterValue}`,
              );
            });
            // Sync filters with queryString
            newSearchParams = {
              ...newSearchParams,
              [`q_${key}`]: `${accessorkey} ${type} ${filterValue}${additionalFilter ? ` and ${additionalFilter}` : ''}`,
            };
          },
        );
      }
    }
    setSearchParams(newSearchParams);
  };
};
