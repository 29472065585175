import { ColumnSort } from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';

export const generateOrderByValue = (sortBy?: ColumnSort): string =>
  sortBy ? `${sortBy.id}${sortBy.desc ? ' desc' : ''}` : '';

export const setSortingState = (
  stateCallbackFn?: Dispatch<SetStateAction<ColumnSort | undefined>>,
  id?: string,
) => {
  if (!stateCallbackFn || !id) return;
  stateCallbackFn((prevState) => generateState(id, prevState));
};

const generateColumnSort = (id: string): ColumnSort => ({ desc: true, id: id });

const generateState = (
  id: string,
  prevState?: ColumnSort,
): ColumnSort | undefined => {
  if (!prevState || prevState.id !== id) return generateColumnSort(id);
  if (!prevState.desc) return undefined;
  return { desc: false, id: prevState.id };
};

export const getSortBy = (
  sortByState?: ColumnSort,
  id?: string,
): 'none' | 'desc' | 'asc' => {
  if (!sortByState || !id || sortByState.id !== id) return 'none';
  return sortByState.desc ? 'desc' : 'asc';
};
