export const getQueryParamsObj = (
  searchParams: IterableIterator<[string, string]>,
) => {
  let params = {};
  for (const entry of searchParams) {
    params = {
      ...params,
      [entry[0]]: entry[1],
    };
  }
  return params;
};
