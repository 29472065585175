import { EPageActionType } from '../types/enum/pageActionType';

export type TPageAction = {
  type: EPageActionType;
  payload?: {
    itemsOnPage?: number;
    total?: number;
    page?: number;
  };
};
export type TPageState = {
  $top: number;
  $skip: number;
  totalItems: number;
  totalPages: number;
  currentPage: number;
};

export const pageReducer = (
  state: TPageState = {
    $top: 15,
    $skip: 0,
    totalItems: 0,
    totalPages: 1,
    currentPage: 1,
  },
  action?: TPageAction,
) => {
  if (!state || !action) throw new Error('incorrect initialization');
  const { itemsOnPage = 15, total = 0, page = 1 } = action.payload || {};

  const newCurrentPage = Math.min(state.currentPage + 1, state.totalPages);
  const newSkip = (newCurrentPage - 1) * state.$top;

  const prevCurrentPage = Math.max(state.currentPage - 1, 1);
  const prevSkip = (prevCurrentPage - 1) * state.$top;

  const setPageCurrentPage = Math.min(Math.max(page, 1), state.totalPages);
  const setPageSkip = (setPageCurrentPage - 1) * state.$top;

  switch (action?.type) {
    case EPageActionType.INIT:
      return {
        ...state,
        totalItems: total,
        $top: itemsOnPage,
        totalPages: Math.ceil(total / itemsOnPage) || 1,
      };
    case EPageActionType.NEXT:
      return { ...state, $skip: newSkip, currentPage: newCurrentPage };
    case EPageActionType.PREVIOUS: {
      return { ...state, $skip: prevSkip, currentPage: prevCurrentPage };
    }
    case EPageActionType.SETPAGE: {
      return { ...state, $skip: setPageSkip, currentPage: setPageCurrentPage };
    }
    case EPageActionType.SETPAGESIZE: {
      return { ...state, $top: itemsOnPage };
    }
    default:
      throw new Error('no action');
  }
};
