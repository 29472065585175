import { Dispatch, Reducer, useMemo, useReducer } from 'react';
import {
  pageReducer,
  TPageAction,
  TPageState,
} from '../helpers/paginationReducer';
import { EPageActionType } from '../types/enum/pageActionType.ts';

type TPaginationReturn = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  $top: number;
  $skip: number;
  pageDispatch: Dispatch<TPageAction>;
};

export const usePagination = (
  itemsOnPage: number,
  total: number,
): TPaginationReturn => {
  const [{ totalItems, totalPages, currentPage, $skip, $top }, pageDispatch] =
    useReducer<Reducer<TPageState, TPageAction>>(pageReducer, {
      $top: itemsOnPage,
      $skip: 0,
      totalItems: total,
      totalPages: 1,
      currentPage: 1,
    });
  useMemo(() => {
    if (currentPage > totalPages) {
      pageDispatch({
        payload: { page: totalPages },
        type: EPageActionType.SETPAGE,
      });
    }
  }, [totalPages, currentPage]);

  return { currentPage, totalPages, totalItems, pageDispatch, $top, $skip };
};
